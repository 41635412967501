import React, {useState, useEffect,useRef} from 'react';
import {Card, Table, Tag, Tooltip, message, Button, Divider,Spin} from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Add from "../../images/new/plus_icon_yellow.svg"
import AppLayout from '../../components/layout';
import {Routes} from '../../utils/route'
import Register from './addCustomer';
import UserView from './CustomerView';
import AvatarStatus from '../../components/shared-components/AvatarStatus';
import userData from './user-list.data.json';
import {navigate} from 'gatsby';
import API from '../../api';
import {CustomerList} from '../../actions/customerAction'
import {connect} from 'react-redux';
import getColumnSearchProps from '../../components/input/TableFilter'
import DataTable from '../../components/data-display/table/DataTable';
import {TextCapitalize, TextUpperCase, usaFormatNumber } from '../../utils/helper'; 
import PageHeader from '../../components/pageHeader/PageHeader';
import "./customer.css"
import ActionButton from "../../images/new/action_icon.svg"
const Customer = props => {
  const [state, setState] = useState ({
    users: [],
    userProfileVisible: false,
    selectedUser: null,
    searchText: '',
searchedColumn: '',
  });
  
const searchInput = useRef(null);
  const [loading, setLoading] = useState (true);
  const [load, setLoad] = useState (true);
  const {customerList} = API;
  useEffect (() => {
      const callback=(val)=>{
        if(val===true){
          setLoading(false)
          const _callback=(value)=>{
            setLoad(value)
          }
          props.CustomerList(_callback)
        }
      }
      Routes('manager',callback)
  }, []);
  useEffect(()=>{
      const _users = props.customerList;
      setState ({...state, users: _users});
  },[props.customerList])


  useEffect (
    () => {
      const _users = [...users];
      _users.push (props.newCustomerData);
      setState ({...state, users: _users});
    },
    [props.newCustomerData]
  );
  useEffect (
    () => {
      const _users = [...users];
      if (props.updateCustomerData !== undefined) {
        _users.filter ((item, i) => {
          if (
            parseInt (props.updateCustomerData.clients_cust_id) ===
            parseInt (item.clients_cust_id)
          ) {
            _users[i] = props.updateCustomerData;
          }
        });
      }
      // _users.push(props.updateCustomerData)
      setState ({...state, users: _users});
    },
    [props.updateCustomerData]
  );

  useEffect (
    () => {
      const _users = [...users];
      _users &&
        _users.map (item => {
          if (item.clients_cust_id === props.customerId) {
            item.status = props.customer_status;
          }
        });
      setState ({...state, users: _users});
    },
    [props.customerId, props.customer_status]
  );
  const sorter=(x,y)=>{
  
  }
  const deleteUser = userId => {
    setState ({
      ...state,
      users: state.users.filter (item => item.client_id !== userId),
    });
    message.success ({content: `Deleted user ${userId}`, duration: 2});
  };

  const showUserProfile = userInfo => {
    setState ({
      ...state,
      userProfileVisible: true,
      selectedUser: userInfo,
    });
  };

  const closeUserProfile = () => {
    setState ({
      ...state,
      userProfileVisible: false,
      selectedUser: null,
    });
  };
  const {users, userProfileVisible, selectedUser} = state;
  const tableColumns=[
    {name: "CUSTOMER ID",label: "Cust. Id",options: {filter: true,sort: true,customBodyRender: value =>value,
      setCellHeaderProps: () => ({
        style: {
          width:100,
          textAlign:"center"
        }
      })
    }},
    {name: "CUSTOMER NAME",label: "Customer Name",options: {filter: true,sort: true,customBodyRender: value =>value,
      setCellHeaderProps: () => ({
        style: {
          width:155,
          paddingRight:0,
        }
      })}},
    // {name: "FIRST NAME",label: "FIRST NAME",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
    // {name: "LAST NAME",label: "LAST NAME",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
    {name: "PHONE",label: "Phone No.",options: {filter: true,sort: true,customBodyRender: value =>usaFormatNumber(value)}},
    {name: "STATUS",label: "Status",options: {filter: false,sort: true,customBodyRender: value =>{
      return <div>
      {(()=>{
        if(value===1){
          return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#0FA36824",color:"#0FA368"}}>Active</span>
        }else{
          return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#FD8F461A ",color:"#FD8F46"}}>Inactive</span>
        }
      })()}
  </div>
    }}},
    {name: "STATE",label: "State",options: {filter: true,sort: true,customBodyRender: value =>TextUpperCase(value)}},
    {name: "CITY",label: "City",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
    {name: "Actions",label: "Action",options: {filter: false,sort: false,   setCellProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        right: "0",
        background: "#fff",
        zIndex: 100,
        textAlign: 'center',
      }
    }),
    setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        right: 0,
        color:'black',
        fontWeight:"700",
        // background: "#1890ffd1",
        zIndex: 101
      }
    })}},
  ]
  const tableData=[]
  users&&users.map((item)=>{
    tableData.push([item.clients_cust_id,item.name,item.phone,
    item.status,item.state,item.city,
   <img src={ActionButton} alt="icon" onClick={() => showUserProfile (item)} style={{cursor:"pointer"}}/>
        ])
  })
  if(!loading){
  return (
    <AppLayout>
      <PageHeader button={true} title='customer list' buttonContent={
      <Register
          Button={<><span style={{marginRight:"7px"}}><PlusOutlined /></span><span>Add New Customer</span></>}
          title="ADD CUSTOMER"
          custom={true}
          className="wash_po_add_edit_btn"
          /> }
          />
          
        <DataTable 
        loader={load}
        header={tableColumns}
        data={tableData}
        customer="customer"
        rowKey="id" />
        <UserView
          data={selectedUser}
          visible={userProfileVisible}
          close={() => {
            closeUserProfile ();
          }}
        />
    </AppLayout>
  );
}else{
  return(<div className="spinner-main"><Spin/></div>)
}
};
const mapStateToProps = state => ({
  loading: state.client.loading,
  customer_status: state.customer.customer_status,
  newCustomerData: state.customer.newCustomerData,
  updateCustomerData: state.customer.updateCustomerData,
  customerId: state.customer.customerId,
  customerList:state.customer.customerList
});
export default connect (mapStateToProps, {CustomerList}) (Customer);
