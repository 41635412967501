import React,{useState,useRef} from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Input, Button } from "antd";
import Highlighter from "react-highlight-words";

   const getColumnSearchProps = (state,searchInput,setState,dataIndex) => ({
       
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {searchInput = node;}}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex,setState,state)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex,setState,state)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters,setState,state)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <style>{`
          .ant-table-filter-trigger-container-open, .ant-table-filter-trigger-container:hover, .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover{
            background:linear-gradient(rgb(250, 164, 4),rgb(224, 156, 29));
          }
          `}</style>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : 'gray',fontSize:18 }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select());
        }
      },
      render: text =>
        state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    });
  
  const  handleSearch = (selectedKeys, confirm, dataIndex,setState,state) => {
      confirm();
      setState({...state,
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    };
  
   const handleReset = (clearFilters,setState,state) => {
      clearFilters();
      setState({...state, searchText: '' });
    };

    export default getColumnSearchProps;